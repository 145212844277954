<template>
  <div v-if="isShow" class="p-1 mt-2">
    <div class="d-flex justify-content-between">
      <span class="font-weight-bold">อัตราจ่าย</span>
      <span class="font-weight-light">{{groupTitle}}</span>
    </div>

    <table class="table table-bordered table-striped table-sm small text-light">
      <thead class="thead-light">
        <tr>
          <th class="text-center">
            <div>{{marketTitle}}</div>
            <div>({{rateTitle}})</div>
          </th>
          <th class="text-center">
            <div>จ่าย</div>
            <div>(บาท)</div>
          </th>
          <th class="text-center">
            <div>ลด</div>
            <div>(%)</div>
          </th>
          <th class="text-center">
            <div>ขั้นต่ำ</div>
            <div>(บาท)</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr :class="{'text-dark': !isAvailable('threeNumberTop')}">
          <td class="text-left">3 ตัวบน</td>
          <td class="text-center">{{ pay('threeNumberTop') | amountFormat }}</td>
          <td class="text-center">{{ discount('threeNumberTop') | amountFormat }}</td>
          <td class="text-center">{{ limit('threeNumberTop') }}</td>
        </tr>
        <tr :class="{'text-dark': !isAvailable('threeNumberTode')}">
          <td class="text-left">3 ตัวโต๊ด</td>
          <td class="text-center">{{ pay('threeNumberTode') | amountFormat }}</td>
          <td class="text-center">{{ discount('threeNumberTode') | amountFormat }}</td>
          <td class="text-center">{{ limit('threeNumberTode') }}</td>
        </tr>
        <tr :class="{'text-dark': !isAvailable('twoNumberTop')}">
          <td class="text-left">2 ตัวบน</td>
          <td class="text-center">{{ pay('twoNumberTop') | amountFormat }}</td>
          <td class="text-center">{{ discount('twoNumberTop') | amountFormat }}</td>
          <td class="text-center">{{ limit('twoNumberTop') }}</td>
        </tr>
        <tr :class="{'text-dark': !isAvailable('twoNumberBottom')}">
          <td class="text-left">2 ตัวล่าง</td>
          <td class="text-center">{{ pay('twoNumberBottom') | amountFormat }}</td>
          <td class="text-center">{{ discount('twoNumberBottom') | amountFormat }}</td>
          <td class="text-center">{{ limit('twoNumberBottom') }}</td>
        </tr>
        <tr :class="{'text-dark': !isAvailable('runTop')}">
          <td class="text-left">วิ่งบน</td>
          <td class="text-center">{{ pay('runTop') | amountFormat }}</td>
          <td class="text-center">{{ discount('runTop') | amountFormat }}</td>
          <td class="text-center">{{ limit('runTop') }}</td>
        </tr>
        <tr :class="{'text-dark': !isAvailable('runBottom')}">
          <td class="text-left">วิ่งล่าง</td>
          <td class="text-center">{{ pay('runBottom') | amountFormat }}</td>
          <td class="text-center">{{ discount('runBottom') | amountFormat }}</td>
          <td class="text-center">{{ limit('runBottom') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'RoundPayrate',
  data() {
    return {}
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isShow() {
      return this.currentRouteName === 'PlayRoundId' && this.rate
    },
    groupTitle() {
      return this.$store.state?.round?.groupTitle
    },
    marketTitle() {
      return this.$store.state?.round?.marketTitle
    },
    rate() {
      return this.$store.state.round.rate
    },
    rateTitle() {
      return this.rate?.rateTitle || ''
    }
  },
  methods: {
    isAvailable(type) {
      return this.rate.openBets[type]?.isAvailable
    },
    pay(type) {
      return (this.rate.openBets[type]?.pay || 0)//.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    discount(type) {
      return (this.rate.openBets[type]?.discount || 0)//.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    min(type) {
      return (this.rate.betLimit[type]?.min || 0)//.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    max(type) {
      return (this.rate.betLimit[type]?.max || 0)//.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    limit(type) {
      return `${this.min(type)} - ${this.max(type)}`
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  tr {
    td {
      font-size: 90%;
      vertical-align: middle;
    }
  }
}
</style>
